import React, { useState, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { axiosAPI } from '../helpers/config';
import ContactPageImage from '../images/pageheader/conatct3.png';

const Contact = () => {

    const [state, setState] = useState({});

    useEffect(() => {
        axiosAPI.get('contactus')
        .then(res => {
            const ContactData = res.data.data
            setState(ContactData)
            
        })
    }, [])
        return(
            <>
                <PageHelmet pageTitle='Contact Us' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> 
                <div className="rn-page-title-area pt--120 pb--190 bg_image"  data-black-overlay="5" style={{backgroundImage : `url(${state?.langitude})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title" style={{color: '#a60808'}}>CONTACT US</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="contact-us">
                    <div className="container">
                        <h3 className="contact-title">Contact Us</h3>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <FiHeadphones />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Phone Number</h4>
                                                <p><a href={`tel:${state?.phone}`}>{state?.phone}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <FiMail />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Email Address</h4>
                                                <p><a href={`mailto:${state?.email}`}>{state?.email}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="offset-md-1 col-md-5">
                                <ContactTwo />
                            </div>
                        </div>
                        <h3 className="contact-title">Our Locations</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="thumbnail mb_md--30 mb_sm--30 map-ifram">
                                    <h6>{state?.address}</h6>
                                    <iframe src={state?.privacy_policy} title="myFrame" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0" data-fillr-frame-id="1208045895"></iframe>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="thumbnail mb_md--30 mb_sm--30 map-ifram">
                                    <h6>{state?.second_address}</h6>
                                    <iframe src={state?.terms_conditions} title="myFrame" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0" data-fillr-frame-id="1208045895"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </>
        )
    }
export default Contact