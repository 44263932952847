import React from "react";
import ResetPassword from "../component/auth/ResetPassword";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const ResetPasswordPage = () => {

    return(
        <>
            <PageHelmet pageTitle='Reset Password' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Reset Password'}  isbread={true} />
            <main className="page-wrapper">
                <ResetPassword />
            </main>
            <Footer />
        </>
    )
}

export default ResetPasswordPage;