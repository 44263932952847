import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Register from "../component/auth/Register";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LocalKeys } from "../helpers/config";

const SignUp = () => {

    const history = useHistory();

    useEffect(() => {
        if(localStorage.getItem(LocalKeys.TOKEN)) {
            history.push('/profile');
        }
    }, [])

    return(
        <>
            <PageHelmet pageTitle='Sign Up' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Sign Up'} isbread={true}  />
            <main className="page-wrapper">
                <Register />
            </main>
            <Footer />
        </>
    )
}

export default SignUp;