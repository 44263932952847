import React, { useEffect } from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Engineers from '../component/Engineers';
import { LocalKeys } from '../helpers/config';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UserProfileHandler } from '../redux/actions/Auth';

const Book = () => {

    const { push } = useHistory();

    const dispatch = useDispatch();

    const { userProfile } = useSelector((state) => state.auth);

    useEffect(() => {

        dispatch(UserProfileHandler());

        if (localStorage.getItem(LocalKeys.TOKEN)) {

            if (userProfile.is_active === 0) {
                push('/verfication')
            }
            
        } else {
            push('/login');
        }
    },[dispatch,userProfile,push])

    return (
        <>
            <PageHelmet pageTitle='BOOK A SESSION' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'BOOK A SESSION'}  isbread={true} />
            <main className="page-wrapper">
                <Engineers />
            </main>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            <Footer />
        </>
        
    )
}

export default Book