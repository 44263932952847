import ActionTypes from '../../helpers/ActionTypes';

const initialState = {
    userData: {},
    userProfile: {},
    userRegisterData:{},
    sendVerficationCode : {},
    resendVerficationCode : {},
    forgetPasswordData : {},
    resetPasswordData : {},
    checkCodeData : {}
}

const Auth =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.USER_REGISTER:
            return { ...state, userRegisterData: action.payload };
        case ActionTypes.USER_LOGIN:
            return { ...state, userData: action.payload };
        case ActionTypes.SEND_VERFICATION_CODE:
            return { ...state, sendVerficationCode: action.payload };
        case ActionTypes.RESEND_VERFICATION_CODE:
            return { ...state, resendVerficationCode: action.payload };
        case ActionTypes.USER_DATA:
            return { ...state, userProfile: action.payload };
        case ActionTypes.FORGET_PASSWORD:
            return { ...state, forgetPasswordData: action.payload };
        case ActionTypes.RESET_PASSWORD:
            return { ...state, resetPasswordData: action.payload };
        case ActionTypes.CHECK_VERFICATION_CODE:
            return { ...state, checkCodeData: action.payload };
        case ActionTypes.USER_LOGOUT:
            return { ...state, userData: {} };
        default:
            return state;
    }
};

export default Auth;