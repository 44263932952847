import React, { useState, useEffect } from "react";
import buildCalender from "./build";
import dayStyles, { beforeToday } from "./styles";
import CalenderHeader from "./CalenderHeader";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { DateOffDataHandler } from "../../../redux/actions/Engineers";
import { useParams } from "react-router-dom";
import { ShowTostHandler } from "../../../helpers/help";
import ErrorDate from '../../../images/02.svg';

const Calender = ({ value, onChange,finalTime }) => {

  let { id } = useParams();

  const dispatch = useDispatch();

  const { DateOffData } = useSelector((state) => state.bookingData);

  useEffect(() => {
      dispatch(DateOffDataHandler(id,value.format("YYYY-MM-DD")))
  },[dispatch,value,id])

  const [calender, setCalender] = useState([]);

  const slots = ["10:00 am", "11:00 am", "12:00 pm", "01:00 pm", "02:00 pm", "03:00 pm", "04:00 pm", "05:00 pm", "06:00 pm", "07:00 pm", "08:00 pm", "09:00 pm", "10:00 pm", "11:00 pm", "12:00 am", "01:00 am", "02:00 am", "03:00 am", "04:00 am", "05:00 am", "06:00 am", "07:00 am", "08:00 am", "09:00 am"];

  const [select, setSelect] = useState({});

  const [state, setState] = useState({ [value.format("YYYY-MM-DD")]: slots });

  const [activeDays, setactiveDays] = useState('');

  const [modal, setModal] = useState(false);

  const [ final, setFinal ] = useState({});

  const toggle = () => setModal(!modal);

  useEffect(() => {

    setCalender(buildCalender(value));

  },[value]);

  useEffect(() =>{
    finalTime(final)
  },[final])

  console.log(final)

  return (
    <div className="calendar">
      <CalenderHeader value={value} setValue={onChange} />
      <div className="row">
        <div className="col-md-12">
          <div className="calender-body">
            <div className="day-names">
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((d) => (
                <div key={d} className="week-day">
                  {d}
                </div>
              ))}
            </div>
            {calender.map((week) => (
              <div className="week" key={week}>
                {week.map((day) => (
                  <div
                    key={day}
                    className={`${dayStyles(day, value) + " day"} `}
                    onClick={() => {
                      !beforeToday(day) && onChange(day);
                      !beforeToday(day) && toggle();
                      !beforeToday(day) && setState((old) => ({ ...old, [day.format("YYYY-MM-DD")]: slots }));
                      !beforeToday(day) && setactiveDays(day.format("YYYY-MM-DD"))
                      if(Object.keys(final).length === 0) {
                        !beforeToday(day) && setSelect((old) => ({ ...old, [day.format("YYYY-MM-DD")]: [] }) )
                      } else {
                          if(day.format("YYYY-MM-DD") === activeDays) {
                            !beforeToday(day) && setSelect((old) => ({ ...old, [day.format("YYYY-MM-DD")]: [...final[activeDays]] }) )
                          } else {
                            !beforeToday(day) && setSelect((old) => ({ ...old, [day.format("YYYY-MM-DD")]: [] }) )
                          }
                      }
                    }}
                  >
                    {day.format("D").toString()}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="booking-model"
        style={{ backgroundColor: "#FFF" }}
      >
        <ModalHeader toggle={toggle}>{activeDays}</ModalHeader>
        <ModalBody>
          <div className="solts-modal">
            {
              DateOffData.date_off ? 
              <div className="date-error">
                <img src={ErrorDate} alt="" />
                <h1>Sorry, There Are No Available Times For This Day</h1>
              </div>
              : 
              <>
                {
                  state[activeDays]?.map((item,index) => (
                    !DateOffData.slots_off?.includes(item) &&
                    <div className={`slot ${(select[activeDays].includes(item) || final[activeDays]?.includes(item)) && 'active'}`} key={index} onClick={() => {

                      if(!select[value.format("YYYY-MM-DD")].includes(item)) {
                        setSelect((old) => ({ ...old, [activeDays]: [...select[value.format("YYYY-MM-DD")],item] }) )
                      } else {
                        select[value.format("YYYY-MM-DD")].splice(select[value.format("YYYY-MM-DD")].indexOf(item),1)
                        setSelect((old) => ({ ...old, [activeDays]: [...select[value.format("YYYY-MM-DD")]] }) )
                      }
                      
                    }}>
                      {item}
                    </div>
                  ))
                }
              </>
            }
          </div>
        </ModalBody>
        <div className="modal-btn">
            <button onClick={
              (e) => {
                e.preventDefault();
                // select[activeDays].length !== 0 && setFinal(select);
                for( const youssef of Object.keys(select) ) {
                  if(select[youssef].length !== 0 ) {
                    setFinal((old) => ({...old, [youssef] : select[youssef]}))
                    toggle();
                  }
                  
                }
                ShowTostHandler("Date Selected", "success");
              }
            }>Done</button>
          </div> 
      </Modal>
    </div>
  );
};

export default Calender;
