import ActionTypes from '../../helpers/ActionTypes';

const initialState = {
    BookingForm: {},
    bookingData: {},
    cancelBooking : {},
    SingleBookingData : {},
    ServiceNeeded : [],
    Slots : []
}

const Booking =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.BOOKING_FORM:
            return { ...state, BookingForm: action.payload };
        case ActionTypes.BOOKING_DATA:
            return { ...state, bookingData: action.payload };
        case ActionTypes.CANCEL_BOOKING:
            return { ...state, cancelBooking: action.payload };
        case ActionTypes.BOOKING_DETAILS:
            return { ...state, SingleBookingData: action.payload,
                                ServiceNeeded: action.payload.services_needed,
                                Slots: action.payload.slots
            };
        default:
            return state;
    }
};

export default Booking;