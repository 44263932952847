import React from 'react'
import { Link } from 'react-router-dom'
const CallAction = () => {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="inner text-left">
                            <span>READY TO DO THIS</span>
                            <h2>Let's get to work</h2>
                        </div>
                    </div>
                    <div className="col-md-4 p-5 col-xs-12">
                        <Link className="rn-button-style--2 btn_solid mt-1" to="/book"><span>BOOK A SESSION</span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;