import React, { useEffect } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PortfolioList from "../elements/portfolio/PortfolioList";
import CallAction from "../elements/callaction/CallAction";
import ContactOne from '../elements/contact/ContactOne'
import HomeSlider from "../elements/HomeSlider";
import HomeAbout from "../elements/HomeAbout";
import { useDispatch, useSelector } from "react-redux";
import { GetInformationDataHandler } from "../redux/actions/Information";
import { Link } from "react-router-dom";
import WorkBox from "../component/WorkBox";
import { GetHomePageDataHandler } from "../redux/actions/HomeApi"


const Home = () => {

    const dispatch = useDispatch();

    const { ServicesData, Information } = useSelector((state) => state.homeData);

    const { WorksData } = useSelector((state) => state.homePageData);
    
    useEffect( () => {
        dispatch(GetInformationDataHandler());
        dispatch(GetHomePageDataHandler());
    }, [])
    
    return(
        <> 
            <Header logo="light" colorblack="color--black"/>
            <HomeSlider title="The Garage" description="WE CREATE IMMERSIVE AUDIO EXPERIENCES" bg={Information.home_banner} video={Information.home_video}/>
            {/* <HomeSlider title="The Garage" description="We Make Mountains of Sound out of THE GARAGE." bg={Information.home_banner}/> */}
            <div className="portfolio-area ptb--120 bg_image bg_image--3">
                <div className="portfolio-sacousel-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-left service-style--3 mb--15">
                                    <h2 className="title">Our Services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <PortfolioList services={ServicesData} />
                        </div>
                    </div>
                </div>
            </div>
            <HomeAbout title="About Us" subTitle={Information.home_about_hint} description={Information.home_about_body} img={Information.home_about_image} />
            <section className="home-works">
                <div className="container">
                    <div className="section-title">
                        <h2>Our Work</h2>
                        <p>Samples of Our Work</p>
                    </div>
                    <div className="row mt-5 mb-5">
                        {
                            WorksData.map(item => (
                                <div className="col-md-3" key={item.id}>
                                    <WorkBox img={item.image} title={item.name} link={item.video} cat={item.category} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <div className="works-link text-center mb-5">
                <Link to="/works" className='rn-button-style--2 btn_solid mt-1' style={{backgroundColor: "#a60808", borderColor : "#a60808", color: "#FFF"}}>See All Works</Link>
            </div>
            <CallAction />
            <Footer />
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </>
    )
}


export default Home;