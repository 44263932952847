import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { ForgetPasswordHandler } from "../../../redux/actions/Auth";
import { useHistory } from "react-router-dom";
import ResetPassword from "../ResetPassword";

const ForgetPassword = () => {

    const dispatch = useDispatch();

    const { push } = useHistory();

    const [ email, setEmail ] = useState('');

    const [ error, setError ] = useState('');

    const [ show, setShow ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const HandelValidation = () => {
        let Valid = true;
        if(email === '') {
            setError('Email is required');
            Valid = false;
        }
        return Valid;

    }

    const HandelSubmit = (e) => {
        e.preventDefault();
        if(HandelValidation()){
            setLoading(true); 
            dispatch(ForgetPasswordHandler(email, () => {
                setShow(true)
            }, () => {
                setLoading(false);
            }))
        }
    }

    return(
        <section className="auth">
            <div className="container">
                {
                    show ? 
                    <ResetPassword email={email} />
                    :
                    <div className="auth-body">
                        <div className="auth-header">
                            <h1 className='title'>Reset Password </h1>
                            <p className='text'>Please enter your email address. You will receive a Code to create a new password via email.</p>
                        </div>
                        <form className='auth-form' onSubmit={HandelSubmit}>
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Email" onChange={
                                (e) => {
                                    setEmail(e.target.value);
                                     setError('');
                                }
                                } />
                                {
                                     error && <span className="text-danger">{error}</span>
                                }
                            </div>
                            {
                                loading?
                                <button disabled className='btn'>Reset Password</button>
                                :
                                <button className='btn' type="submit">Reset Password</button>
                            }
                        </form>
                    </div>
                }
            </div>
        </section>
    )
}

export default ForgetPassword;