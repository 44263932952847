import React, { useEffect, useState }  from 'react'
import Step2 from './List/Step2'
import Step3 from './List/Step3'
import Step4 from './List/Step4'
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { BookingFormHandler } from '../../redux/actions/Booking';
import { ShowTostHandler } from '../../helpers/help';

const Wizard = () => {

    const { id } = useParams();

    const dispatch = useDispatch();

    const [ state, setState ] = useState({
        project_name: "",
        direct : "",
        agency : "",
        production_house : "",
        project_duration : 0,
        unit_duration : "",
        project_type_id : 0,
        copies_number : 0,
        sound_engineer_id : 0,
        services_needed : [],
        slots : {},
        step : 1
    })

    const [ error, setError ] = useState([])

    useEffect(() => {
        setState((old) => ({...old, sound_engineer_id: id}))
    },[state.sound_engineer_id,id])

    // Handel Next

    function handelNext(e) {
        e.preventDefault();

        if(Object.keys(state.slots).length !== 0) {
            setState((old) => ({...old, step : state.step + 1}))
        } else {
            ShowTostHandler("Please Select Date ", "error");
        }
        
        if(state.step === 2 ){
            setState((old) => ({...old, step : 2}))
        }
    }

    // Handel Before

    function handelBefore(event)  {
        event.preventDefault();
        setState((old) => ({...old, step : state.step - 1}))
        if(state.step === 1 ){
            setState((old) => ({...old, step :  1}))
        }
    }

    // Handel Step Two

    const HandelStepTwo = (project_name,direct,agency,production_house,project_duration,unit_duration,copies_number,services_needed,project_type_id) => {
        setState((old) => ({...old,
            project_name : project_name,
            direct : direct,
            agency : agency,
            production_house : production_house,
            project_duration : project_duration,
            unit_duration : unit_duration,
            project_type_id : project_type_id,
            copies_number : copies_number,
            services_needed : services_needed,

        }))
    }

    const handelStepThree = (ourSlots) => {
        setState((old) => ({...old, slots : ourSlots}))
    }

    // Handel Submit Booking Form

    const HandelSubmit = (e) => {
        e.preventDefault();

        if( state.project_name === "" ) {
            ShowTostHandler("Please Enter Your Project Name", "error");
            setError((old) => [...old, "ProjectName" ])
        }
        if( state.direct === "" ) {
            ShowTostHandler("Please Enter Your Director", "error");
            setError((old) => [...old, "Director" ])
        }
        if( state.agency === "" ) {
            ShowTostHandler("Please Enter Your Agency", "error");
            setError((old) => [...old, "Agency" ])
        }
        if( state.production_house === "" ) {
            ShowTostHandler("Please Enter Your Production House", "error");
            setError((old) => [...old, "ProductionHouse" ])
        }
        if( state.project_duration === 0 ) {
            ShowTostHandler("Please Enter Your Project Duration", "error");
            setError((old) => [...old, "ProjectDuration" ])
        }
        if( state.copies_number === 0 ) {
            ShowTostHandler("Please Enter Your Number Of Copies", "error");
            setError((old) => [...old, "NumberOfCopies" ])
        }
        if( state.project_type_id === 0 ) {
            ShowTostHandler("Please Select Your Project Type", "error");
            setError((old) => [...old, "ProjectType" ])
        }
        if( state.services_needed.length === 0 ) {
            ShowTostHandler("Please Select Your Services Needed", "error");
            setError((old) => [...old, "ServicesNeeded" ])
        }

        const formData = new FormData();
        
        formData.append('project_name', state.project_name)
        formData.append('direct', state.direct)
        formData.append('agency', state.agency)
        formData.append('production_house', state.production_house)
        formData.append('project_duration', state.project_duration)
        formData.append('unit_duration', state.unit_duration)
        formData.append('copies_number', state.copies_number)
        for(let i =0; i < state.services_needed.length; i++) {
            formData.append(`services_needed[${i}]`, state.services_needed[i])
        }
        formData.append('project_type_id', state.project_type_id)
        formData.append('sound_engineer_id', state.sound_engineer_id)

        for( let item in state.slots ) {
            for( let j = 0; j < state.slots[item].length; j++ ) {

                formData.append(`slots[${item}][${j}]`, state.slots[item][j])
            }
        }
        if(error.length === 0) {
            dispatch(BookingFormHandler(formData, () => {
                setState((old) => ({...old, step : 3}))
            }))
        }
        
    }

    return(
        <>
            <section className="wizzard"> 
                <div className="container">
                    <form className="wizzard-body" onSubmit={HandelSubmit}>
                        <div className={`form-steps ${state.step === 1 && "d-block"} `}>
                            <Step3 dateTime={handelStepThree}  />
                        </div>
                        <div className={`form-steps ${state.step === 2 && "d-block"} `}>
                            <Step2 professional={HandelStepTwo} />
                        </div>
                        {
                            state.step === 3 && <Step4/>
                        }
                        {
                            state.step === 2 && <button className="book-btn">BOOK NOW</button> 
                        }
                    </form>
                    {
                        state.step === 3 ? null :
                            <div className="wizzard-btns">
                            {
                                state.step === 1 ? 
                                <Link to={`${process.env.PUBLIC_URL + "/book/" }`} className="btn">Back</Link> 
                                :
                                <button className="btn" onClick={handelBefore} >Back</button>
                            }
                            <button className={state.step === 2 ? "btn disable" : "btn"} onClick={handelNext} >Next</button>
                        </div>
                    }
                </div>
            </section>
        </>
    )

}

export default Wizard;