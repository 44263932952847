import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo/logo-light.png';
import { useDispatch, useSelector } from 'react-redux';
import { GetServicesPageDataHandler } from '../../redux/actions/Services';
import {FaInstagram ,FaFacebookF , FaTiktok, FaVimeoV} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { GetHomePageDataHandler } from '../../redux/actions/HomeApi';

const Footer = () => {

    const dispatch = useDispatch();

    const { ServicesPageData } = useSelector((state) => state.servicesData);

    const { informativeData } = useSelector((state) => state.homePageData);

    useEffect(() => {
        dispatch(GetServicesPageDataHandler());
        dispatch(GetHomePageDataHandler());
    },[]);

    return (
        <div className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-content">
                                <div className="footer-logo">
                                    <Link to="/">
                                        <img src={Logo} alt="Logo images"/>
                                    </Link>
                                </div>
                                <p className='footer-text'>
                                    The Garage Sound Studio is a professional studio based in Cairo, 
                                    Egypt. Our journey began in 2015 with works in drama, TV series, films, dubbing, 
                                    and commercials.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-content">
                                <h3 className="footer-title">Quick Links</h3>
                                <ul className="footer-links">
                                    <li>
                                        <a href="/service">Services</a>
                                    </li>
                                    <li>
                                        <a href="/about">About</a>
                                    </li>
                                    <li>
                                        <a href="/works">Our Work</a>
                                    </li>
                                    <li>
                                        <a href="/our-team">Our Team</a>
                                    </li>
                                    <li>
                                        <a href="/contact">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-content">
                                <h3 className="footer-title">Our Services</h3>
                                <ul className="footer-links">
                                    {
                                        ServicesPageData.map((val, i) => (
                                            <li key={i}>
                                                <a href={`/service#${val.name.split(" ").join("").toLowerCase()}`}>{val.name}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-content">
                                <h3 className="footer-title">Contact Us</h3>
                                <ul className="footer-links">
                                    <li>
                                        <a href="https://www.google.com/maps/place/30%C2%B003'04.0%22N+31%C2%B012'26.7%22E/@30.051107,31.204847,17z/data=!4m4!3m3!8m2!3d30.0511111!4d31.2074167?hl=en&entry=ttu" target="_blank"><i className="fi fi-rr-marker"></i> <span className='label'>{informativeData.address}</span></a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/maps?ll=30.050804,31.20789&z=17&t=m&hl=en&gl=EG&mapclient=embed&cid=9669546837873664489" target="_blank"><i className="fi fi-rr-marker"></i> <span className='label'>{informativeData.second_address}</span></a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${informativeData.email}`}><i className="fi fi-sr-envelope"></i> <span className='label'>{informativeData.email}</span></a>
                                    </li>
                                    <li>
                                        <a href={`tel:${informativeData.phone}`}><i className="fi fi-sr-phone-call"></i> <span className='label'>{informativeData.phone}</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="footer-social">
                                <h6 className='social-title'>Follow us on social media</h6>
                                <ul className='social-list'>
                                    <li>
                                        <a href={informativeData.facebook} target="_blank"><FaFacebookF /></a>
                                    </li>
                                    <li>
                                        <a href={informativeData.instgram} target="_blank"><FaInstagram /></a>
                                    </li>
                                    <li>
                                        <a href={informativeData.twitter} target="_blank"><FaXTwitter /></a>
                                    </li>
                                    <li>
                                        <a href={informativeData.tiktok} target="_blank"><FaTiktok /></a>
                                    </li>
                                    <li>
                                        <a href={informativeData.vimeo} target="_blank"><FaVimeoV /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text">
                                <p>Copyright © 2021 The Garage. All Rights Reserved. Powerd By <a href="https://boldbrand.co/" title="Bold Brand" target="_blank" rel="noopener noreferrer">Bold Brand</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;