import React from 'react'
import Slider from "react-slick"
import { slideSlick } from "../../page-demo/script";
import { Link } from 'react-router-dom';
import HomeVideo from "../../images/home-one.mp4"

const HomeSlider = ({title,description,bg, video}) => {

    return(
        <div className="slider-wrapper color-white">
            {/* <div className="slider-activation slider-digital-agency" style={{backgroundImage: `url(${bg})`}}> */}
            <div className="slider-activation slider-digital-agency">
            <video src={video} autoPlay loop muted className="home-video"/>
                <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                        <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image `}  data-black-overlay="5" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner text-left`}>
                                            <h1 className="title">{title} </h1>
                                            <p className="description"> {description} </p>
                                            <div className="slide-btn">
                                                <Link className="rn-button-style--2 btn-primary-color" to="/contact" >Contact Us</Link>
                                                <Link className="rn-button-style--2 btn-primary-color ml-5 btn-cus-book" to="/book" >BOOK A SESSION</Link>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Slider>
            </div>
        </div>
    )
}

export default HomeSlider