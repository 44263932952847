import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoginHandler } from '../../../redux/actions/Auth';
import ShowPassImage from '../../../images/icons/showpass.svg';
import HidePassImage from '../../../images/icons/hidden.svg';

const Login = () => {

    const dispatch = useDispatch();

    const { push } = useHistory();

    const [ state, setState ] = useState({
        email:"",
        password: ""
    })

    const [ error, setError ] = useState({
        email:"",
        password: ""
    })

    const [ showPassword, setShowPassword ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const HandelShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const HandelValidation = () => {

        let Valid = true;

        if(state.email === "") {

            setError((old) => ({...old, email : "Email Address Is Required"}));
            Valid = false;
        }

        if(state.password === ""){
            setError((old) => ({...old, password : "Password Is Required"}));
            Valid = false;
        }

        return Valid;
    }

    const HandelSubmit = (e) => {
        e.preventDefault();
        if(HandelValidation()) {
            setLoading(true);
            dispatch(LoginHandler(state, () => {
                push('/')
            }, () => {
                setLoading(false);
            }))
        }
    }

    return(
        <section className="auth">
            <div className="container">
                <div className="auth-body">
                    <div className="auth-header">
                        <h1 className='title'>Login </h1>
                        <p className='text'>Login using Email and Password</p>
                    </div>
                    <form className='auth-form' onSubmit={HandelSubmit}>
                        <div className="form-group">
                            <input type="email" className="form-control" placeholder="Email" onChange={
                                (e) => {
                                    const text = e.target.value
                                    setState((old) => ({...old, email : text}));
                                    setError((old) => ({...old, email : ""}));
                                }
                            } />
                            {
                                error.email!== "" && <span className="text-danger">{error.email}</span>
                            }
                        </div>
                        <div className="form-group">
                            <input type={ showPassword ? "text" : "password" } className="form-control" placeholder="Password" onChange={
                                (e) => {
                                    const text = e.target.value;
                                    setState((old) => ({...old, password : text}));
                                     setError((old) => ({...old, password : ""}));
                                }
                            } />
                            {
                                showPassword ? 
                                <img className='show-icon' src={HidePassImage} alt="Password Icon" onClick={HandelShowPassword} title="Hide Password" /> 
                                :
                                <img className='show-icon' src={ShowPassImage} alt="Password Icon" onClick={HandelShowPassword} title="Show Password" />
                            }
                        </div>
                            {
                                error.password!== "" && <div className="text-danger mb-3">{error.password}</div>
                            }
                        {
                            loading? 
                            <button disabled className='btn'>Login</button>
                            :
                            <button className='btn' type='submit'>Login</button>
                        }
                    </form>
                    <div className="feats">
                        <div className="auth-info"> Create New Account <Link to="/sign-up">Sign Up</Link>
                        </div>
                        <div className="auth-info">
                            <Link to="/lost-password">Forgotten password?</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;