import { axiosAPI  } from "../../helpers/config";
import ActionTypes from '../../helpers/ActionTypes';


// Handle Home Page Data

const HomePageData = (payload) => ({
    type: ActionTypes.HOME_PAGE_DATA,
    payload: payload,
});

export const GetHomePageDataHandler = () => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.get("home");
        if(data.status_code === 200){
            dispatch(HomePageData(data.data));
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};