import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import CallAction from "../elements/callaction/CallAction";
import Footer from "../component/footer/Footer";
import HomeAbout from "../elements/HomeAbout";
import { useDispatch, useSelector } from "react-redux";
import { GetAboutPageDataHandler } from "../redux/actions/AboutUS";
import { GetInformationDataHandler } from "../redux/actions/Information";
// import TestimonialOne from '../blocks/testimonial/TestimonialOne';
import AboutPageImage from '../images/pageheader/about.jpeg';


const About = () => {

    const dispatch = useDispatch();

    const { AboutInformation, Opinions } = useSelector((state) => state.aboutData);

    const { Information } = useSelector((state) => state.homeData);

    useEffect( () => {
        dispatch(GetAboutPageDataHandler());
        dispatch(GetInformationDataHandler());
    }, [dispatch])

        return(
            <>
                <PageHelmet pageTitle='About' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-page-title-area pt--120 pb--190 bg_image "  data-black-overlay="5" style={{backgroundImage : `url(${AboutPageImage})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title " style={{color: '#a60808'}}>ABOUT US</h2>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                        <div className="container">
                            <div className="section-title service-style--3 text-left mb--25">
                                <h2 className="title text-center" style={{ color: "#a60808", marginBottom : '35px' }}>The Most Award Winning Sound Studio In Egypt</h2>
                                {/* <h2 className="title">We Make Mountains of Sound out of THE GARAGE.</h2> */}
                            </div>
                        </div>
                        <div className="inner">
                            <div className="content-wrapper">
                                <div className="content">
                                    <h4 style={{color: '#a60808'}}>{AboutInformation.about_title}</h4>
                                    <p>{AboutInformation.about_content}</p>
                                </div>
                            </div>
                            <div className="thumbnail">
                                <div className="image">
                                    <img src={AboutInformation.about_image} alt="Finding Images"/>
                                </div>
                            </div>
                        </div>
                </div>
                <HomeAbout 
                    title={Information.home_about_hint} 
                    description={Information.home_about_body} 
                    img={Information.home_about_image} 
                    page='about'
                    mission={Information.privacy_policy}
                    vission={Information.terms_conditions}
                />
                {/* <TestimonialOne data={Opinions} /> */}
                <div style={{marginTop:"100px"}}>
                    <CallAction />
                </div>
                
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                
                <Footer />

            </>
        )
} 
        
export default About