import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { BookingDataHandler, CancelBookingHandler, SingleBookingHandler } from "../../../redux/actions/Booking";
import Loader from "../../../component/Loader";
// import ErrorImage from '../../../../public/assets/images/01.svg';
import { Link } from "react-router-dom";

const Appointments = () => {

    const dispatch = useDispatch();

    const { bookingData, SingleBookingData, ServiceNeeded, Slots } = useSelector((state) => state.appointmentBooking);

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const [model, setModel] = useState(false);

    const [ loader, setLoader ] = useState(true)

    useEffect(() => {
        dispatch(BookingDataHandler(
            () => {
                setLoader(false)
            }
        ));
    },[dispatch])

    const HandelCancel = (id) => {
        dispatch(CancelBookingHandler(id));
    }

    const HandelSingleBooking = (id) => {
        dispatch(SingleBookingHandler(id));
    }
    console.log(SingleBookingData)

    return(
        <section className="appointments">
            <Nav tabs >
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}> My Appointments</NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                {
                    loader ? <Loader /> : 
                    <>
                        {
                    Object.keys(bookingData).length === 0 ? 
                    <div className="date-error">
                        {/* <img src={ErrorImage} alt="" /> */}
                        <h1>Sorry, You Haven't Booked an Appointment Yet</h1>
                        <Link to='/book' className='rn-button-style--2 btn_solid mt-1'>BOOK A SESSION</Link>
                    </div>
                    : 
                    <TabPane tabId="1">
                    <Row>
                        {
                            Object.keys(bookingData).map(item => (
                                <Col lg="6" md="8" sm="6" key={item}>
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="user-info">
                                                {
                                                    bookingData[item].slice(0,1).map( image => (
                                                        <div className="user-img" key={image.id}>
                                                            <img src={image.image} alt={image.name} className="img-fluid" />
                                                        </div>
                                                    ))
                                                }
                                                <div className="user">
                                                    <div className="info">
                                                        {
                                                            bookingData[item].slice(0,1).map( name => (
                                                                <h5 key={name.id}>{name.name}</h5>
                                                            ))
                                                        }
                                                        <div className="spec">Sound Engineer</div>
                                                    </div>
                                                    <div className="date-box">
                                                        <div className="date">Date: {item}</div>
                                                        {
                                                            bookingData[item].slice(0,1).map( time => (
                                                                <div className="date" key={time.id}>Time: { time.time } </div>
                                                            ))
                                                        }
                                                    </div>
                                                    {
                                                        bookingData[item].slice(0,1).map( item => (
                                                            <div className="app-btns" key={item.id}>
                                                                <button className={`${(item.status === 4 || item.status === 3) && "finish"}`} onClick={
                                                                    (e) => {
                                                                        e.preventDefault();
                                                                        HandelSingleBooking(item.booking_id);
                                                                        setModel(!model)
                                                                    }
                                                                } >booking Details</button>
                                                                {(item.status === 4 || item.status === 3) ?  null : <button className="cancel" onClick={
                                                                    (e) => {
                                                                        e.preventDefault();
                                                                        HandelCancel(item.booking_id)
                                                                    }
                                                                }>Cancel</button>}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            {
                                                bookingData[item].slice(0,1).map(status => (
                                                    <div className={`status ${status.status === 1 ? "pending" : status.status === 2 ? "confirmed" : "canceled"}`} key={status.id}>{status.status === 1 ? "Pending" : status.status === 2 ? "Confirmed" : status.status === 4 ? "Canceled" : "Refused"}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </TabPane>
                }
                    </> 
                }
            </TabContent>

            <Modal isOpen={model} toggle={() => setModel(!model)} className="custom" >
                <ModalHeader toggle={() => setModel(!model)}>Booking Details</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Project Name</h6>
                                <div className="text">{SingleBookingData.project_name}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Director</h6>
                                <div className="text">{SingleBookingData.direct}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Agency</h6>
                                <div className="text">{SingleBookingData.agency}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Production House</h6>
                                <div className="text">{SingleBookingData.production_house}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Project Duration</h6>
                                <div className="text">{SingleBookingData.project_duration}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Number Of Copies</h6>
                                <div className="text">{SingleBookingData.copies_number} Copy</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Project Type</h6>
                                <div className="text">{SingleBookingData.project_type_name}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <h6 className="title">Sound Engineer</h6>
                                <div className="text">{SingleBookingData.name}</div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <h6 className="title">Services Needed</h6>
                                {
                                    ServiceNeeded.map( (service,index) => (
                                        <span className="service-needed" key={index}>{service}</span>
                                        ))
                                }
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <h6 className="title">Date</h6>
                                <div className="text">{SingleBookingData.date}</div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <h6 className="title">Slots</h6>
                                {
                                    Slots.map( (slot,index) => (
                                        <span className="service-needed" key={index}>{slot.time}</span>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </section>
    )
}

export default Appointments;