import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PlayIcon from '../../images/play-icon.png';

const WorkBox = ({img,title,link, cat}) => {

    const [model, setModel] = useState(false);

    return(
        <div className="work-box">
            <div className="work-badge">{cat}</div>
            <div className="work-img">
                <img src={img} alt={title} />
                <div className="play-icon" onClick={() => setModel(!model)}>
                    <img src={PlayIcon} alt="Play Video"/>
                </div>
            </div>
            <h3 className="work-title">{title.length > 20 ? `${title.slice(0,20)}...` : title}</h3>
            <Modal isOpen={model} toggle={() => setModel(!model)} className="works-modal"  >
                <ModalHeader toggle={() => 
                    {
                        setModel(!model);
                        setPlay(true)
                    }
                }>
                    {title}
                </ModalHeader>
                <ModalBody>
                    <iframe title="vimeo-player" src={link} width="100%" height="500" frameBorder="0" allowFullScreen></iframe>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default WorkBox;