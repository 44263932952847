import ActionTypes from '../../helpers/ActionTypes';

const initialState = {
    EngineersData: [],
    ProjectTypesData : [],
    ServiceNeeded : [],
    DateOffData : {}
}

const Engineers =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ENGINEERS_DATA:
            return { ...state, EngineersData: action.payload.sound_engineers,
                                ProjectTypesData : action.payload.project_types,
                                ServiceNeeded : action.payload.services_needed
                    };
        case ActionTypes.DATE_OFF:
            return { ...state, DateOffData: action.payload };
        default:
            return state;
    }
};

export default Engineers;