import React, { useState } from "react";
import { NavLink, useHistory } from 'react-router-dom';
import { LogOutHandler } from "../../../redux/actions/Auth";
import { useDispatch } from 'react-redux';

const ProfileSideBar = ({title}) => {

    const dispatch = useDispatch();

    const { push } = useHistory();

    const [ loading, setLoading ] = useState(false);

    const HandelLogout = (e) => {

        e.preventDefault();
        setLoading(true);
        dispatch(LogOutHandler(() => {
            push('/')
        }))
    }

    return(
        <div className="side-bar">
            <h2 className='title'>{title}</h2>
            <ul className='profile-menu'>
                <li>
                    <NavLink to="/profile">My Account</NavLink>
                </li>
                <li>
                    <NavLink to="/appointment">Appointment</NavLink>
                </li>
                <li>
                    {
                        loading? 
                            <NavLink to="/logout" className="loading" onClick={() => e.preventDefault()}> Logout </NavLink>
                        :
                        <NavLink to="/logout" className="logout" onClick={HandelLogout}> Logout </NavLink>
                    }
                </li>
            </ul>
        </div>
    )
}

export default ProfileSideBar;