import React, { useEffect } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Wizard from '../component/Wizard';
import { LocalKeys } from "../helpers/config";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserProfileHandler } from "../redux/actions/Auth";

const SoundEngineer = () => {

    const { push } = useHistory();

    const dispatch = useDispatch();

    const { userProfile } = useSelector((state) => state.auth);

    useEffect(() => {

        dispatch(UserProfileHandler());

        if (localStorage.getItem(LocalKeys.TOKEN)) {

            if (userProfile.is_active === 0) {
                push('/verfication')
            }
            
        } else {
            push('/login');
        }
    },[])


    return (
        <>
        <PageHelmet pageTitle='Book a Session' />
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
        <Breadcrumb title={'Book a Session'} isbread={false}  />
        <main className="page-wrapper">
            <Wizard />
        </main>
        <Footer />
        </>
    )
}

export default SoundEngineer;