import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SignUpHandler } from "../../../redux/actions/Auth";
import ShowPassImage from '../../../images/icons/showpass.svg';
import HidePassImage from '../../../images/icons/hidden.svg';
import Verfication from "../Verfication";

const Register = () => {

    const dispatch = useDispatch();

    const [ state, setState ] = useState({
        name: "",
        email: "",
        phone:"",
        password: "",
        confirm_password: ""
    })

    const [ errors, setErrors ] = useState({
        name: "",
        email: "",
        phone:"",
        password: "",
        confirm_password: ""
    });

    const [ showPassword, setShowPassword ] = useState(false);

    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const [ showCode, setShowCode ] = useState(false);

    const HandelShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const HandelShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const HandelValidation = () => {

        let Valid = true;

        if(state.name === "") {
            setErrors((old) => ({...old, name : "Name is required"}));
            Valid = false;
        }

        if(state.email === "") {
            setErrors((old) => ({...old, email : "Email is required"}));
            Valid = false;
        }

        if(state.phone === "") {
            setErrors((old) => ({...old, phone : "Phone is required"}));
            Valid = false;
        }

        if(state.password === "") {
            setErrors((old) => ({...old, password : "Password is required"}));
            Valid = false;
        }

        if(state.password !== state.confirm_password) {
            setErrors((old) => ({...old, confirm_password : "Password and Confirm Password not matched"}));
            Valid = false;
        }

        return Valid;
    };

    const HandelSubmit = (e) => {
        e.preventDefault();
        if(HandelValidation()){
            setLoading(true);
            dispatch(SignUpHandler(state,() => {
                setShowCode(true);
            }, () => {
                setLoading(false);
            }));
        }
    }

    return(
        <section className="auth">
            <div className="container">
                {
                    showCode ? <Verfication /> :
                    <div className="auth-body">
                        <div className="auth-header">
                            <h1 className='title'>Sign Up</h1>
                        </div>
                        <form className='auth-form' onSubmit={HandelSubmit}>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Full Name" onChange={
                                    (e) => {
                                        const text = e.target.value;
                                        setState((old) => ({...old, name : text}))
                                    }
                                } />
                                {
                                    errors.name && <span className="text-danger">{errors.name}</span>
                                }
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Email" onChange={
                                    (e) => {
                                        const text = e.target.value;
                                        setState((old) => ({...old, email : text }))
                                    }
                                } />
                                {
                                    errors.email && <span className="text-danger">{errors.email}</span>
                                }
                            </div>
                            <div className="form-group">
                                <input type="phone" className="form-control" placeholder="Phone Number" onChange={
                                    (e) => {
                                        const text = e.target.value;
                                        setState((old) => ({...old, phone : text }))
                                    }
                                } />
                                {
                                    errors.phone && <span className="text-danger">{errors.phone}</span>
                                }
                            </div>
                            <div className="form-group">
                                <input type={ showPassword ? "text" : "password" } className="form-control" placeholder="Password" onChange={
                                    (e) => {
                                        const text = e.target.value;
                                        setState((old) => ({...old, password : text }))
                                    }
                                } />
                                {
                                    showPassword ? 
                                    <img className='show-icon' src={HidePassImage} alt="Password Icon" onClick={HandelShowPassword} title="Hide Password" /> 
                                    :
                                    <img className='show-icon' src={ShowPassImage} alt="Password Icon" onClick={HandelShowPassword} title="Show Password" />
                                }
                            </div>
                            {
                                errors.password && <span className="text-danger">{errors.password}</span>
                            }
                            <div className="form-group">
                                <input type={ showConfirmPassword ? "text" : "password" } className="form-control" placeholder="Confirm Password" onChange={
                                    (e) => {
                                        const text = e.target.value;
                                        setState((old) => ({...old, confirm_password : text }))
                                    }
                                } />
                                {
                                    showConfirmPassword ? 
                                    <img className='show-icon' src={HidePassImage} alt="Password Icon" onClick={HandelShowConfirmPassword} title="Hide Password" /> 
                                    :
                                    <img className='show-icon' src={ShowPassImage} alt="Password Icon" onClick={HandelShowConfirmPassword} title="Show Password" />
                                }
                            </div>
                            {
                                errors.confirm_password && <div className="text-danger mb-3">{errors.confirm_password}</div>
                            }
                            {
                                loading ?
                                <button disabled className='btn'>Sign Up</button>
                                :
                                <button className='btn' type="submit">Sign Up</button>
                            }
                        </form>
                        <div className="feats">
                            <div className="auth-info">
                                Alreay have account? <Link to="/login">Sign in</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default Register;