import React from "react";
import LoadImage from '../../images/Bars.svg';
const Loader = () => {

    return(
        <div className="loader">
            <img src={LoadImage} alt="" />
        </div>
    )
}

export default Loader;