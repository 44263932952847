import ActionTypes from '../../helpers/ActionTypes';

const initialState = {

    WorksData : [],
    informativeData : {}

}

const HomeApi =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.HOME_PAGE_DATA:
            return{ ...state, WorksData :  action.payload.works, informativeData : action.payload.information}
        default:
            return state;
    }
};

export default HomeApi;