import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Home from './pages/Home';
import Service from './pages/Service'
import Works from './pages/Works'
import About from './pages/About'
import Contact from "./pages/Contact";
import error404 from "./pages/error404";
import Book from "./pages/Book";
import VoiceOver from "./pages/VoiceOver";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import OurTeam from './pages/OurTeam';
import LoginPage from './pages/LoginPage';
import SignUp from './pages/SignUp';
import LostPassword from './pages/LostPassword';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Profile from './pages/Profile';
import Appointment from './pages/Appointment';
import SoundEngineer from './pages/SoundEngineer';
import { Provider } from "react-redux";
import { store } from './redux/store';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import VerficationEmailPage from './pages/VerficationEmailPage';
import VerficationCodePage from './pages/VerficationCodePage';


const Root = () => {
        return(
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL + "/" }`} component={Home}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/service" }`} component={Service}/>
                        {/* <Route  exact path={`${process.env.PUBLIC_URL + "/service:id" }`} component={Service}/> */}
                        <Route  path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/about"}`} component={About}/>
                        <Route  exact path={`${process.env.PUBLIC_URL + "/book"}`} component={Book}/>
                        <Route  exact path={`${process.env.PUBLIC_URL + "/book/:id" }`} component={SoundEngineer}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/works"}`} component={Works}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/our-team"}`} component={OurTeam}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/voice-over"}`} component={VoiceOver}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/login"}`} component={LoginPage}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/sign-up"}`} component={SignUp}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/lost-password"}`} component={LostPassword}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/reset-password"}`} component={ResetPasswordPage}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/profile"}`} component={Profile}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/appointment"}`} component={Appointment}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/verfication"}`} component={VerficationEmailPage}/>
                        <Route  path={`${process.env.PUBLIC_URL + "/verfication-email"}`} component={VerficationCodePage}/>
                        <Route component={error404}/>
                    </Switch>
                    <ToastContainer />
                </BrowserRouter>
            </Provider>
        )
    }

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();


