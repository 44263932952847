import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

const PortfolioList =  ({services}) =>{
        return(
            <> 
                {services.map((value , index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                        <div className={`portfolio text-center mt--40`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail `} style={{backgroundImage: `url(${value.image})`}}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><Link to={`/service#${value.name.split(" ").join("")}`}>{value.name}</Link></h4>
                                    {/* <p>{value.description_en.slice(0,100)}...</p> */}
                                    <div className="portfolio-button">
                                        <Link className="rn-btn" to={`/service#${value.name.split(" ").join("").toLowerCase()}`}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    }
export default PortfolioList;