import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ResetPasswordHandler } from "../../../redux/actions/Auth";
import { useHistory } from "react-router-dom";
import ShowPassImage from '../../../images/icons/showpass.svg';
import HidePassImage from '../../../images/icons/hidden.svg';

const ResetPassword = ({email}) => {

    const dispatch = useDispatch();

    const { push } = useHistory();

    const [ state, setState ] = useState({
        email : email,
        token_reset : "",
        password : '',
        confirm_password : ''
    })

    const [ error, setError ] = useState({
        token_reset : "",
        password : '',
        confirm_password : ''
    })

    const [ showPassword, setShowPassword ] = useState(false);

    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);

    const HandelShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const HandelShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const [ loading, setLoading ] = useState(false);

    const HandelValidation = () => {

        let Valid = true;

        if(state.token_reset === "") {
            setError((old) => ({...old, token_reset : "Verification Code is required"}));
            Valid = false;
        }

        if(state.password === "") {
            setError((old) => ({...old, password : 'New Password is Required'}));
            Valid = false;
        }

        if(state.password!== state.confirm_password) {
            setError((old) => ({...old, confirm_password : 'New Password and Confirm Password not matched'}));
            Valid = false;
        }

        return Valid;

    }

    const HandelSubmit = (e) => {
        e.preventDefault();
        if(HandelValidation()){
            setLoading(true);
            dispatch(ResetPasswordHandler(state,
                () => {
                    push('/login')
                }
            ))
        }
    }

    console.log(state)

    return(
        <section className="auth">
            <div className="container">
                <div className="auth-body">
                    <div className="auth-header">
                        <h1 className='title'>Reset Password</h1>
                    </div>
                    <form className='auth-form' onSubmit={HandelSubmit}>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Verification Code" onChange={
                                (e) => {
                                    const text = e.target.value;
                                    setState((old) => ({...old, token_reset : text}));
                                    setError((old) => ({...old, token_reset : ""}));
                                }
                            } />
                        </div>
                        {
                            error.token_reset && <span className="text-danger">{error.token_reset}</span>
                        }
                        <div className="form-group">
                            <input type={ showPassword ? "text" : "password" } className="form-control" placeholder="New Password" onChange={
                                (e) => {
                                    const text = e.target.value;
                                    setState((old) => ({...old, password : text}));
                                     setError((old) => ({...old, password : ''}));
                                }
                            } />
                            {
                                showPassword ? 
                                <img className='show-icon' src={HidePassImage} alt="Password Icon" onClick={HandelShowPassword} title="Hide Password" /> 
                                :
                                <img className='show-icon' src={ShowPassImage} alt="Password Icon" onClick={HandelShowPassword} title="Show Password" />
                            }
                        </div>
                        {
                            error.password && <span className="text-danger">{error.password}</span>
                        }
                        <div className="form-group">
                            <input type={ showConfirmPassword ? "text" : "password" } className="form-control" placeholder="Confirm Password" onChange={
                                (e) => {
                                    const text = e.target.value;
                                    setState((old) => ({...old, confirm_password : text}));
                                     setError((old) => ({...old, confirm_password : ''}));
                                }
                            } />
                            {
                                showConfirmPassword ? 
                                <img className='show-icon' src={HidePassImage} alt="Password Icon" onClick={HandelShowConfirmPassword} title="Hide Password" /> 
                                :
                                <img className='show-icon' src={ShowPassImage} alt="Password Icon" onClick={HandelShowConfirmPassword} title="Show Password" />
                            }
                        </div>
                        {
                            error.confirm_password && <div className="text-danger mb-3">{error.confirm_password}</div>
                        }
                        {
                            loading ?
                            <button disabled className='btn'>Reset Password</button>
                            :
                            <button className='btn' type="submit">Reset Password</button>
                        }
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword;