import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Verfication from "../component/auth/Verfication";

const VerficationCodePage = () => {

    return(
        <>
            <PageHelmet pageTitle='Email Verfication' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Email Verfication'}  isbread={true} />
            <main className="page-wrapper">
                <Verfication />
            </main>
            <Footer />
        </>
    )
}

export default VerficationCodePage;