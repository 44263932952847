import React from 'react'
import { AiOutlineArrowLeft,AiOutlineArrowRight } from 'react-icons/ai'

const CalenderHeader = ({value, setValue}) => {

    function currentMonth(){

        return value.format("MMMM")
    }
    function currentYear(){

        return value.format("YYYY")
    }

    function previous () {
        return value.clone().subtract(1, "month")
    }
    function Next () {
        return value.clone().add(1, "month")
    }

    function thisMonth(){
        return value.isSame(new Date(), "month")
    }

    return(
        <div className="calender-header">
            <div className="prev" onClick={() => !thisMonth() && setValue(previous())}>
                {!thisMonth()? <AiOutlineArrowLeft/> : null}
            </div>
            <div>{currentMonth()} {currentYear()}</div>
            <div className="next" onClick={() => setValue(Next())}><AiOutlineArrowRight/></div>
        </div>
    )

}

export default CalenderHeader