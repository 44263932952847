import React from 'react'
import { Link } from 'react-router-dom';
const Step4 = () => {

    return(
        <div className='success-booking'>
            <h5 className="text-center text-success">
                Thanks and Wating For Confirmation Email.
            </h5>
            <div className="btn-finish">
                <Link to='/appointment' className='rn-button-style--2 btn_solid mt-1'>Your Appointments</Link>
            </div>
        </div>
    )

}

export default Step4;