import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import {FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CallAction from "../elements/callaction/CallAction";
import { useDispatch, useSelector } from "react-redux";
import { GetServicesPageDataHandler } from "../redux/actions/Services";
import ServicePageImage from '../images/pageheader/services-page-header.jpg';
import { useHistory } from "react-router-dom";


const Service = () => {


    const dispatch = useDispatch();

    const { ServicesPageData } = useSelector((state) => state.servicesData);

    useEffect( () => {

        // const hash = window.location.hash;

        // const hash = window.location.hash;
        // if (hash) {
        // const targetElement = document.querySelector(hash);
        // if (targetElement) {
        //     targetElement.scrollIntoView({ behavior: 'smooth' });
        // }
        // }

        dispatch(GetServicesPageDataHandler());
    }, [])


        return(
            <>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-page-title-area pt--120 pb--190 bg_image "  data-black-overlay="5" style={{backgroundImage : `url(${ServicePageImage})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title" style={{color: '#a60808'}}>SERVICES</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-area">
                            {
                                ServicesPageData.map((val , i) => (
                                    <div className={`portfolio ${ (i % 2 !== 0) ? "service-single" : null}`} key={i} id={`${val.name.split(" ").join("").toLowerCase()}`}>
                                        <div className="container">
                                            <div className="row align-items-center service-one-wrapper">
                                                <div className={ (i % 2 !== 0) ? "col-md-6 order-2" : "col-md-6"}>
                                                    <div className="thumb"> 
                                                        <img className="img-fluid" src={val.image} alt="Service Images" /> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="content">
                                                        <div className="inner">
                                                            <h4>{val.name}</h4>
                                                            <p>{val.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <CallAction />
                <Footer />
            </>
        )
}
export default Service;