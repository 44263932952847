import { axiosAPI, LocalKeys  } from "../../helpers/config";
import ActionTypes from '../../helpers/ActionTypes';
import { ShowTostHandler } from "../../helpers/help";

// Handel User Redister Data

const UserRegisterData = (payload) => ({
    type: ActionTypes.USER_REGISTER,
    payload: payload,
});

export const SignUpHandler = (signUpForm, cb, cbError) => {
    return async (dispatch) => {

    try {

        const { data } = await axiosAPI.post("register", signUpForm);

        if(data.status_code === 200){

            localStorage.setItem(LocalKeys.TOKEN, data.data.token);
            dispatch(UserRegisterData(data.data));
            ShowTostHandler(data.message, "success");
            cb && cb();
        } else {
            cbError && cbError();
            ShowTostHandler(data.message.email[0], "error");
            ShowTostHandler(data.message.phone[0], "error");
            ShowTostHandler(data.message.password, "error");
            
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};


// Handle User Login

const UserData = (payload) => ({
    type: ActionTypes.USER_LOGIN,
    payload: payload,
});

export const LoginHandler = (loginForm, cb, cbError) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("login", loginForm);
        if(data.status_code === 200){
            localStorage.setItem(LocalKeys.TOKEN, data.data.token);
            dispatch(UserData(data.data.user));
            ShowTostHandler(data.message, "success");
            cb && cb();
        } else {
            if(data.message.email) {
                ShowTostHandler(data.message.email.toString(), "error");
            } else {
                ShowTostHandler(data.message, "error");
            }
            cbError && cbError();
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handel User Profile Data

const UserProfileData = (payload) => ({
    type: ActionTypes.USER_DATA,
    payload: payload,
});

export const UserProfileHandler = (cb) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("profile");
        
        if(data.status_code === 200 ){
            dispatch(UserProfileData(data.data)); 
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handel Update User Data

export const UserUpdateProfileHandler = (loginFields) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("profile-update",loginFields);
        if(data.status_code === 200){
            dispatch({type : ActionTypes.USER_UPDATE_DATA});
            ShowTostHandler(data.message, "success");
            setTimeout(() => {
                window.location.reload();
            }, 1000)
        } else {
            ShowTostHandler(data.errors.password, "error");
            ShowTostHandler(data.errors.confirm_password, "error");
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handel Send Verfication Code

const SendVerficationData = (payload) => ({
    type: ActionTypes.SEND_VERFICATION_CODE,
    payload: payload,
});

export const SendVerficationCodeHandler = (code, cb) => {

    return async (dispatch) => {

    try {
        const { data } = await axiosAPI.post("verify-email", {
            code : code
        });
        
        if(data.status_code === 200) {

            dispatch(SendVerficationData(data.message));
            ShowTostHandler(data.message, "success");
            cb && cb();
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};


// Handel ReSend Verfication Code

const ResendVerficationData = (payload) => ({
    type: ActionTypes.RESEND_VERFICATION_CODE,
    payload: payload,
});

export const ResendVerficationCodeHandler = (cb) => {

    return async (dispatch) => {

    try {
        const { data } = await axiosAPI.post("resend");
        
        if(data.status_code === 200) {

            dispatch(ResendVerficationData(data.message));
            ShowTostHandler(data.message, "success");
            cb && cb();
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};


// Handel Check Verfication Code

const CheckVerficationData = (payload) => ({
    type: ActionTypes.CHECK_VERFICATION_CODE,
    payload: payload,
});

export const CheckVerficationCodeHandler = (email,code, cb) => {

    return async (dispatch) => {

    try {
        const { data } = await axiosAPI.post("token/check", {
            email : email,
            token_reset : code 
        });
        
        if(data.status_code === 200) {

            dispatch(CheckVerficationData(data.message));
            ShowTostHandler(data.message, "success");
            cb && cb();
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};


// Handle Forget Password

const ForgotPasswordData = (payload) => ({
    type: ActionTypes.FORGET_PASSWORD,
    payload: payload,
});

export const ForgetPasswordHandler = (email, cb, cbError) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("password/email", {
            email : email
        });
        if(data.status_code === 200){
            dispatch(ForgotPasswordData(data.data));
            ShowTostHandler(data.message, "success");
            cb && cb();
        }else {
            ShowTostHandler(data.message, "error");
            cbError && cbError();
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handle Reset Password

const ResetPasswordData = (payload) => ({
    type: ActionTypes.RESET_PASSWORD,
    payload: payload,
});

export const ResetPasswordHandler = (dataForm, cb) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("password/reset", dataForm);
        if(data.status_code === 200){
            dispatch(ResetPasswordData(data.data));
            ShowTostHandler(data.message, "success");
            cb && cb();
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handel Logout

export const LogOutHandler = (cb) => {
    return async (dispatch) => {
        const { data } = await axiosAPI.post("logout");
        try {
            localStorage.removeItem(LocalKeys.TOKEN)
            dispatch({type : ActionTypes.USER_LOGOUT, payload: data.data });
            cb && cb();
        } catch(error) {
            console.log(error.response); 
        }
        
    };
};