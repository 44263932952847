import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetSoundEngineersDataHandler } from '../../../redux/actions/Engineers';

const Step2 = ({professional}) => {

    const dispatch = useDispatch();

    const { ProjectTypesData, ServiceNeeded } = useSelector((state) => state.bookingData);

    useEffect(() => {
        dispatch(GetSoundEngineersDataHandler())
    },[dispatch])

    const [state, setState] = useState({
        project_name : "",
        direct : "",
        agency: "",
        production_house : "",
        project_duration : 0,
        unit_duration: "Seconds",
        copies_number : 0,
        services_needed : [],
        project_type_id : 0,
    })

    useEffect(() => {
        professional(
            state.project_name,
            state.direct,
            state.agency,
            state.production_house,
            state.project_duration,
            state.unit_duration,
            state.copies_number,
            state.services_needed,
            state.project_type_id,
            )
    },[state])

    return(
        <>
            <h3 className="step-title">Fill professional Information</h3>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Project Name" 
                        onChange={
                            (e) => 
                            {const text = e.target.value
                            setState((old) => ({...old , project_name : text }))}
                        }
                        />
                    </div>
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Director" 
                        onChange={
                            (e) => 
                            {const text = e.target.value
                            setState((old) => ({...old , direct : text }))}
                        }
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Agency" 
                        onChange={
                            (e) => 
                            {const text = e.target.value
                            setState((old) => ({...old , agency : text }))}
                        }
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Production House" 
                        onChange={
                            (e) => 
                            {const text = e.target.value
                            setState((old) => ({...old , production_house : text }))}
                        }
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="row">
                    <div className="col-md-8 pr-0">
                        <div className="form-group">
                            <input type="number" className="form-control" min='0' placeholder="Project Duration" 
                                onChange={
                                    (e) => 
                                    {const text = e.target.value
                                    setState((old) => ({...old , project_duration : text }))}
                                }
                            />
                        </div>
                    </div>
                    <div className="col-md-4 pl-0">
                        <div className="form-group">
                            <select className="form-control" defaultValue={'Seconds'} style={{ position : 'relative', top: '6px', backgroundColor: "#a60808", color: "#FFF", borderColor: "#a60808" }} 
                                onChange={(e) => {
                                    const text = e.target.value;
                                    setState((old) => ({...old, unit_duration : text}))
                                }}
                            >
                                <option className="main-select" value='Seconds'>Second</option>
                                <option className="main-select" value='Minuts'>Minute</option>
                                <option className="main-select" value='Hours'>Hour</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <input type="number" className="form-control" min='0' placeholder="Number Of Copies" 
                        onChange={
                            (e) => 
                            {const text = e.target.value
                            setState((old) => ({...old , copies_number : text }))}
                        }
                    />
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-group">
                    <select className="form-control" defaultValue={'ProjectType'}
                        onChange={
                            (e) => 
                            {const text = e.target.value
                            setState((old) => ({...old , project_type_id : text }))}
                        }
                    >
                        <option className="main-select" disabled value='ProjectType'>Project Type</option>
                        {
                            ProjectTypesData.map( project => (<option key={project.id} value={project.id}>{project.name}</option>) )
                        }
                    </select>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-group">
                    <h6>Services Needed</h6>
                    <div className="service-needed">
                        {
                            ServiceNeeded.map( service => 
                                (<div key={service.id} className={`single-service ${state.services_needed.includes(service.name) &&  "active"} `} 
                                    onClick={ () => {
                                        if(!state.services_needed.includes(service.name)){
                                            setState((old) => ({...old, services_needed : [...state.services_needed, service.name]}))
                                        } else {
                                            state.services_needed.splice(state.services_needed.indexOf(service.name), 1)
                                            setState((old) => ({...old, services_needed : [...state.services_needed]}))
                                        }
                                        
                                    }}
                                >{service.name}</div>) 
                                )
                        }
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Step2