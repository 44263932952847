import ActionTypes from '../../helpers/ActionTypes';

const initialState = {
    ServicesPageData : []

}

const Services =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SERVICES_PAGE_DATA:
            return{ ...state, ServicesPageData :  action.payload}
        default:
            return state;
    }
};

export default Services;