import React, { useEffect } from 'react';
import Login from "../component/auth/Login";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { LocalKeys } from '../helpers/config';
import { useHistory } from 'react-router-dom';

const LoginPage = () => {

    const { push } = useHistory();

    useEffect(() => {
        
        localStorage.getItem(LocalKeys.TOKEN)  && push('/profile')
    },[push])

    return(
        <>
            <PageHelmet pageTitle='Login' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Login'} isbread={true}  />
            <main className="page-wrapper">
                <Login />
            </main>
            <Footer />
        </>
    )
}

export default LoginPage;