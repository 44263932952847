import React, { useState, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp  } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { axiosAPI } from '../helpers/config'
import Loader from "../component/Loader";
import ErrorImage from '../images/Error.png';

const VoiceOver = () => {

    const [ state, setState ] = useState([]);

    const [ name, setName ] = useState('');

    const [ age, setAge ] = useState('');

    const [ gender, setGender ] = useState('');

    const [ language, setLanguage ] = useState('');

    const [ languageType, setLanguageType ] = useState('');

    const [ langs, setLangs ] = useState([]);

    const [ langsType, setLangsType ] = useState([]);

    const [ load, setLoad ] = useState(true);


    useEffect( () => {
        axiosAPI.get('languages')
        .then( res => {
            setLangs(res.data.data.languages);
        })
    },[])

    useEffect( () => {
        axiosAPI.get(`languages/${parseInt(language)}`)
        .then( res => {
            setLangsType(res.data.data.language_type);
        })
    },[language])

    useEffect( () => {
        axiosAPI.post('voices', {
            name: name,
            age : age,
            gender: gender,
            language_id: language,
            language_type_id: languageType

        })
        .then(res => { 
            setState(res.data.data.voices.data);
            if(res.data.status_code === 200) {
                setLoad(false);
            }
        })
    }, [name,age,gender,language,languageType])

    return (
        <>
            <PageHelmet pageTitle='Voice Over' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">VOICE OVER</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <section className="voice-over">
                <div className="container">
                    <div className='voice-filter'>
                        <form className='filter-form'>
                            <div className="row">
                                <div className="col-md-3 p-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control one" placeholder='Search...' 
                                            onChange={
                                                (e) => {
                                                    setName(e.target.value);
                                                    setLoad(true);
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="p-0 col-md-1">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder='Age' 
                                            onChange={
                                                (e) => {
                                                    setAge(e.target.value);
                                                    setLoad(true);
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="p-0 col-md-2">
                                    <div className="form-group">
                                        <select class="form-control" onChange={
                                                    (e) => {
                                                        setGender(e.target.value);
                                                        setLoad(true);
                                                    }
                                            }>
                                            <option value="">All</option>
                                            <option value="Male">Male</option>
                                            <option value="Female"> Female</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="p-0 col-md-3">
                                    <div className="form-group">
                                        <select class="form-control" onChange={
                                                    (e) => {
                                                        setLanguage(e.target.value);
                                                        setLoad(true);
                                                    }
                                            }>
                                            <option value="">All Languages</option>
                                            {
                                                langs.map(item => (
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="p-0 col-md-3">
                                    <div className="form-group">
                                        <select class="form-control two" onChange={
                                                    (e) => {
                                                        setLanguageType(e.target.value);
                                                        setLoad(true);
                                                    }
                                            }>
                                            <option value="">All Languages Type</option>
                                            {
                                                langsType.map(item => (
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="voice-body">
                        {
                            load && <Loader />
                        }
                        {
                            state.length === 0 ? 
                            <div className="text-center" style={{marginTop: "80px"}}>
                                <img src={ErrorImage} alt="" />
                            </div> :
                            <div className="row">
                            {
                                state.map(item => (
                                    <div className="col-md-6" key={item.id}>
                                        <div className="vos-box">
                                            <div className="media">
                                                <img className="vos-img" src={item.image} alt="Generic placeholder"/>
                                                <div className="media-body">
                                                    <h5 className="mt-0">{item.name}</h5>
                                                    <div className="audio-sam my-3">
                                                        <audio controls src={item.voice} />
                                                        <audio controls src={item.voice} />
                                                        <audio controls src={item.voice} />
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="voice-feats">
                                                <li><span><img src="/assets/images/vos/speech.png" alt="Test" className="pr-2" /> {item.gender}</span></li>
                                                {/* <li><span><img src="/assets/images/vos/speech.png" className="pr-2" /> {item.age}</span></li> */}
                                                <li><span><img src="/assets/images/vos/speech.png" alt="Test" className="pr-2" /> {item.language.name}</span></li>
                                                <li><span><img src="/assets/images/vos/speech.png" alt="Test" className="pr-2" /> {item.language_type.name}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        }
                    </div>
                </div>
            </section>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            <Footer />
        </>
        
    )
}
export default VoiceOver;