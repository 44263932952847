import React, { useState } from 'react'
import Calender from '../Calender';
import moment from 'moment';

const Step3 = ({dateTime}) => {

    const [value, setValue] = useState(moment())

    return(
        <div>

            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <Calender value={value} onChange={setValue} finalTime={dateTime} />
                </div>
            </div> 
        </div>
    )
}

export default Step3