import React, { useEffect } from 'react';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Appointments from '../component/auth/Appointments';
import CallAction from "../elements/callaction/CallAction";
import ProfileSideBar from '../component/auth/ProfileSideBar';
import { LocalKeys } from '../helpers/config';
import { useHistory } from 'react-router-dom';

const Appointment = () => {

    const { push } = useHistory();

    useEffect(() => {

        !localStorage.getItem(LocalKeys.TOKEN) && push('/login')

    },[push])

    return(
        <>
            <PageHelmet pageTitle='Appointment' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Appointment'} isbread={true}  />
            <main className="page-wrapper account">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <ProfileSideBar title="Appointments" />
                        </div>
                        <div className="col-md-9">
                            <Appointments />
                        </div>
                    </div>
                </div>
            </main>
            <CallAction />
            <Footer />
        </>
    )
}

export default Appointment;