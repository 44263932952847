import React, {useState, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import WorkPageImage from '../images/pageheader/works.png';
import { useDispatch, useSelector } from "react-redux";
import { GetWorksPageDataHandler, WorkCategoriesDataHandler } from "../redux/actions/Works";
import WorkBox from "../component/WorkBox";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const Works = () =>  {

        const dispatch = useDispatch();

        const { WorksData, WorkCats } = useSelector((state) => state.worksData);

        const [activeTab, setActiveTab] = useState(0);

        const toggle = tab => {
            if(activeTab !== tab) setActiveTab(tab);
        }

        useEffect(() => {
            dispatch(GetWorksPageDataHandler());
            dispatch(WorkCategoriesDataHandler());
        }, [])

        const FilterWork = WorksData.filter(item => item.work_category_id === activeTab);

        return (
            <>
                <PageHelmet pageTitle='Our Work' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-page-title-area pt--120 pb--190 bg_image"  data-black-overlay="5" style={{backgroundImage : `url(${WorkPageImage})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title" style={{color: '#a60808'}}>OUR WORK</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="page-wrapper page-works">
                    <div className="container">
                        <div className="section-title mb-3">
                            <h2>Our Work</h2>
                            <p>Samples of Our Work</p>
                        </div>
                        <div className="row">
                            <div className="col-md-3 nav-work">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === 0 })} onClick={() => { toggle(0);  }}>All</NavLink>
                                </NavItem>
                                {
                                    WorkCats.map(item => (
                                        <NavItem key={item.id}>
                                            <NavLink className={classnames({ active: activeTab === item.id })} onClick={() => { toggle(item.id);  }}>{item.title}</NavLink>
                                        </NavItem>
                                    ))
                                }
                            </Nav>
                            </div>
                            <div className="col-md-9">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={0}>
                                        <div className="row mt-2">
                                            {
                                                WorksData.map(item => (
                                                    <div className="col-md-4 mt-3" key={item.id}>
                                                        <WorkBox img={item.image} title={item.name} link={item.video} cat={item.category} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </TabPane>
                                    {
                                        WorkCats.map(item => (
                                            <TabPane tabId={item.id} key={item.id}>
                                                <div className="row mt-2">
                                                    {
                                                        FilterWork.map(item => (
                                                            <div className="col-md-4 mt-3" key={item.id}>
                                                                <WorkBox img={item.image} title={item.name} link={item.video} cat={item.category} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </TabPane>
                                        ))
                                    }
                                    
                                </TabContent>
                            </div>
                        </div>
                        
                    </div>
                </main>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />               
            </>
        )
    }
    
export default Works;