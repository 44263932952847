import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import Auth from './reducers/Auth';
import Engineers from "./reducers/Engineers";
import Booking from "./reducers/Booking";
import Information from './reducers/Information';
import Services from './reducers/Services';
import AboutUS from './reducers/AboutUS';
import Works from "./reducers/Works";
import HomeApi from "./reducers/HomeApi";

const rootReducer = combineReducers({
    auth : Auth,
    bookingData : Engineers,
    appointmentBooking : Booking,
    homeData : Information,
    servicesData : Services,
    aboutData : AboutUS,
    worksData : Works,
    homePageData : HomeApi
});


export const store = createStore(rootReducer, compose(applyMiddleware(thunk)));