import ActionTypes from '../../helpers/ActionTypes';

const initialState = {
    WorksData : [],
    WorkCats : []

}

const Works =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.WORKS_PAGE_DATA:
            return{ ...state, WorksData :  action.payload}
        case ActionTypes.WORK_CATEGORIES:
            return{ ...state, WorkCats :  action.payload}
        default:
            return state;
    }
};

export default Works;