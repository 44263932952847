import ActionTypes from '../../helpers/ActionTypes';

const initialState = {
    ServicesData : [],
    Information : {},
    WorksData : [],
    ServicesPageData : []

}

const Information =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.INFORMATION_DATA:
            return { ...state, ServicesData: action.payload.services,
                                Information: action.payload.information,
                                WorksData: action.payload.works
            };
        case ActionTypes.SERVICES_PAGE_DATA:
            return{ ...state, ServicesPageData :  action.payload}
        default:
            return state;
    }
};

export default Information;