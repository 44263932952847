import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserProfileHandler, UserUpdateProfileHandler } from "../../../redux/actions/Auth";

const Account = () => {

    const dispatch = useDispatch();

    const { userProfile } = useSelector((state) => state.auth);

    const [ state, setState ] = useState({
        name: "",
        email:"",
        phone: "",
        password:"",
        confirm_password:""
    })

    useEffect(() => {
        setState((old) => ({...old,
            name: userProfile.name,
            email: userProfile.email,
            phone: userProfile.phone,
            password: userProfile.password,
            confirm_password : userProfile.password

        }))
    },[userProfile])

    useEffect(() => {
        dispatch(UserProfileHandler())
    },[dispatch])

    const HandelSubmit = (e) => {
        e.preventDefault();
        dispatch(UserUpdateProfileHandler(state))
    }


    return(
        <section className="profile">
            <div className="profile-body">
                <div className="profile-header">
                    <h1 className='title'>Edit Profile</h1>
                </div>
                <form className='profile-form' onSubmit={HandelSubmit}>
                    <div className="form-group">
                        <label htmlFor="Name">Full Name</label>
                        <input type="text" className="form-control" id="Name"  defaultValue={state.name} onChange={
                            (e) => {
                                const text = e.target.value;
                                setState((old) => ({...old, name : text}))
                            }
                        } />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Email">Email</label>
                        <div className="form-control email">{state.email}</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="Phone">Phone</label>
                        <input type="phone" className="form-control" id="Phone" defaultValue={state.phone} onChange={
                            (e) => {
                                const text = e.target.value;
                                setState((old) => ({...old, phone: text}))
                            }
                        } />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Password">Password</label>
                        <input type="password" className="form-control" id="Password" placeholder="********" autoComplete="new-password" onChange={
                            (e) => {
                                const text = e.target.value;
                                setState((old) => ({...old, password : text}))
                            }
                        } />
                    </div>
                    <div className="form-group">
                        <label htmlFor="ConfirmPassword">Confirm Password</label>
                        <input type="password" className="form-control" id="ConfirmPassword" placeholder="********" autoComplete="new-password" onChange={
                            (e) => {
                                const text = e.target.value;
                                setState((old) => ({...old, confirm_password : text}))
                            }
                        } />
                    </div>
                    <button className='btn'>Save</button>
                </form>
                </div>
        </section>
    )
}

export default Account;