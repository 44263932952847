import React, { useEffect } from 'react';
import Account from '../component/auth/Account';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ProfileSideBar from '../component/auth/ProfileSideBar';
import { LocalKeys } from '../helpers/config';
import { useHistory } from 'react-router-dom';

const Profile = () => {

    const { push }  = useHistory();


    useEffect(() => {

        !localStorage.getItem(LocalKeys.TOKEN) && push('/login');
    },[push])


    return(
        <>
            <PageHelmet pageTitle='My Account' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'My Account'}  isbread={true} />
            <main className="page-wrapper account">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <ProfileSideBar title="My Account" />
                        </div>
                        <div className="col-md-9">
                            <Account />
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Profile;