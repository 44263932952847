import axios from "axios";

export const LocalKeys = {
    TOKEN: "TOKEN",
};

export const axiosAPI = axios.create({
    baseURL:"https://admin-garage.thegaragesoundstudio.com/api/",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": "en"
    },
});


axiosAPI.interceptors.request.use(
    function(config) {
    const token = localStorage.getItem(LocalKeys.TOKEN); 
    if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
    }
        return config;
    },
        function(error) {
        return Promise.reject(error);
    }
);