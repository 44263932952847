import React,{ useEffect, useState } from "react";
import { Link,useHistory } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import { LocalKeys } from "../../helpers/config";
import { useDispatch, useSelector } from "react-redux";
import { UserProfileHandler } from "../../redux/actions/Auth";
import { FaUserAlt } from "react-icons/fa";
import { LogOutHandler } from "../../redux/actions/Auth";
import Logo from '../../images/logo/logo-light.png';

const Header = (props) =>{

    const { push } = useHistory();

    const menuTrigger =() => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    const CLoseMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
        const { color='default-color' } = props;
        let logoUrl = <img src={Logo} className="img-fluid" alt="The Garage Logo" />;

        const dispatch = useDispatch();

        const { userProfile } = useSelector((state) => state.auth);

        const [ show, setShow ] = useState(false);

        const HandelSowMenu = (e) => {
            e.preventDefault();
            
            setShow(!show);
        }

        useEffect(() => {
            if(localStorage.getItem(LocalKeys.TOKEN)){

                dispatch(UserProfileHandler());
            }
        },[])
        
        return(
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <Link to="/">
                                {logoUrl}
                            </Link>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to="/">Home</Link> </li>
                                <li><Link to="/service" >Services</Link></li>
                                <li><Link to="/about" >About</Link></li>
                                <li><Link to="/works">Our Work</Link></li>
                                <li><Link to="/our-team">Our Team</Link></li>
                                <li><Link to="/contact" >Contact Us</Link></li>
                                {
                                    (localStorage.getItem(LocalKeys.TOKEN) ) ? 
                                    <>
                                        <li className="drop-down">
                                            <Link className="" to="/" onClick={ HandelSowMenu }>
                                                <span><FaUserAlt /> {userProfile.name}</span>
                                            </Link>
                                            <ul className={`sub-menu ${show ? 'show' : ''}`}>
                                                <li><Link to="/profile">Account</Link></li>
                                                <li><Link to="/appointment">Appointment</Link></li>
                                                <li><Link to="/" onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(LogOutHandler(() => {
                                                        push('/')
                                                    }))
                                                }}>Logout</Link></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link className="rn-button-style--2 btn_solid mt-1" to="/book">
                                                <span>BOOK A SESSION</span>
                                            </Link>
                                        </li>
                                    </> : 

                                    <>
                                        <li>
                                            <Link className="rn-button-style--2 btn_solid mt-1" to="/login">
                                                <span>Login</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="rn-button-style--2 btn_solid mt-1" to="/sign-up">
                                                <span>Sign Up</span>
                                            </Link>
                                        </li>
                                    </>
                                }
                            </ul>
                        </nav>
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
export default Header;