import { axiosAPI } from "../../helpers/config";
import ActionTypes from '../../helpers/ActionTypes';

const SoundEngineers = (payload) => ({
    type: ActionTypes.ENGINEERS_DATA,
    payload: payload,
});

export const GetSoundEngineersDataHandler = () => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.get("bookings");
        if(data.status_code === 200){
            dispatch(SoundEngineers(data.data));
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handel Date Off

const DateOff = (payload) => ({
    type: ActionTypes.DATE_OFF,
    payload: payload,
});

export const DateOffDataHandler = (id,date) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.get(`bookings/${id}?date=${date}`);
        if(data.status_code === 200){
            dispatch(DateOff(data.data));
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};