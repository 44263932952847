import { axiosAPI  } from "../../helpers/config";
import ActionTypes from '../../helpers/ActionTypes';


// Handle About Us Page Data

const AboutPageData = (payload) => ({
    type: ActionTypes.ABOUT_PAGE_DATA,
    payload: payload,
});

export const GetAboutPageDataHandler = () => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.get("about_us");
        if(data.status_code === 200){
            dispatch(AboutPageData(data.data));
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};