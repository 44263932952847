import ActionTypes from '../../helpers/ActionTypes';

const initialState = {
    AboutInformation : {},
    OurTeam : [],
    Opinions : []

}

const AboutUS =  (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ABOUT_PAGE_DATA:
            return{ ...state, AboutInformation :  action.payload.information,
                                OurTeam : action.payload.teams,
                                Opinions :  action.payload.opinions
            }
        default:
            return state;
    }
};

export default AboutUS;