import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import {FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CallAction from "../elements/callaction/CallAction";
import { useDispatch, useSelector } from "react-redux";
import { GetAboutPageDataHandler } from "../redux/actions/AboutUS";
import OurTeamPageImage from '../images/pageheader/our-team-page-header.jpg';


const OurTeam = () => {

    const dispatch = useDispatch();

    const { OurTeam } = useSelector((state) => state.aboutData);

    useEffect( () => {
        dispatch(GetAboutPageDataHandler());
    }, [dispatch])


        return(
            <>
                <PageHelmet pageTitle='Our Team' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-page-title-area pt--120 pb--190 bg_image"  data-black-overlay="5" style={{backgroundImage : `url(${OurTeamPageImage})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title" style={{color: '#a60808'}}>Our Team</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-area">
                            {
                                OurTeam.map((val , i) => (
                                    <div className={`portfolio ${ (i % 2 !== 0) ? "service-single" : null}`} key={i} >
                                        <div className="container">
                                            <div className="row align-items-center service-one-wrapper">
                                                <div className={ (i % 2 !== 0) ? "col-md-6 order-2" : "col-md-6"}>
                                                    <div className="thumb"> 
                                                        <img className="img-fluid" src={val.image} alt={val.name} style={{ width: "500px", height: "500px", objectFit : "cover", objectPosition : 'top' }}/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="content">
                                                        <div className="inner">
                                                            <h4>{val.name}</h4>
                                                            <h6>{val.position}</h6>
                                                            <p>{val.bio}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <CallAction />
                <Footer />
            </>
        )
}
export default OurTeam;