import React, { useState } from "react";
import { axiosAPI } from '../../helpers/config'

const ContactTwo = () => {

    const [state, setState] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    })

    const [error, setError] = useState({
        name: "",
        email: "",
        subject: ""
    })

    const [ loading, setLoading] = useState(false);

    const [ success, setSuccess ] = useState(false);

    const handleValidation = () => {

        let valid = true

        if(state.name === ""){
            setError((old) => ({...old, name : "Your Name is Required"}))
            valid = false
        }
        if(state.email === ""){
            setError((old) => ({...old, email : "Your Email is Required"}))
            valid = false
        }
        if(state.subject === ""){
            setError((old) => ({...old, subject : "Your Subject is Required"}))
            valid = false
        }
        if(!state.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            setError((old) => ({...old, email : "The email must be a valid email address."}))
            valid = false
        }

        return valid;
        
    }
    
    const HandelSubmit = (e) => {
        e.preventDefault()
        if(handleValidation()) {
            setLoading(true);
            axiosAPI.post('contactus',state)
            .then(res => {
                if(res.data.status_code === 200) {
                    setSuccess(true);
                    setLoading(false);
                    setState(() => ({ 
                        name: "",
                        email: "",
                        subject: "",
                        message: "",
                    }))

                    const el = document.getElementById('ContactForm');

                    el.reset();

                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                }
            })

        }
            else {
        }
    }
        return(
            <form className="contact-form" id="ContactForm" onSubmit={HandelSubmit}>
                <div className="form-group">
                    <label htmlFor="Name">Name *</label>
                    <input type="text" className="form-control" id="Name" placeholder="ex: Livia Siphron" onChange={
                        (e) => {
                            setState((old) => ({...old, name: e.target.value}));
                            setError((old) => ({...old, name: ""}));
                        }
                    }/>
                    {
                        error.name && <span className="form-text text-danger">{error.name}</span>
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="Email">Email *</label>
                    <input type="text" className="form-control" id="Email" placeholder="ex: john@email.com" onChange={
                        (e) => {
                            setState((old) => ({...old, email: e.target.value}));
                            setError((old) => ({...old, email: ""}));
                        }
                    }/>
                    {
                        error.email && <span className="form-text text-danger">{error.email}</span>
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="Subject">Subject *</label>
                    <input type="text" className="form-control" id="Subject" placeholder="i.e. I want to know about your service" onChange={
                        (e) => {
                            setState((old) => ({...old, subject: e.target.value}));
                            setError((old) => ({...old, subject: ""}));
                        }
                    }/>
                    {
                        error.subject && <span className="form-text text-danger">{error.subject}</span>
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="Message">Message *</label>
                    <textarea className="form-control" id="Message" placeholder="Write Your Message" onChange={
                        (e) => {
                            setState((old) => ({...old, rnMessage: e.target.value}))
                        }
                    }/>
                </div>
                {
                    loading ? 
                    <button disabled className="btn load">SEND</button>
                    :
                    <button className="btn" type="submit">SEND</button>
                }
                {
                    success && <div class="alert alert-success text-center mt-5" role="alert">Message Sent Successfully</div>
                }
            </form>
        )
    }
export default ContactTwo;
