import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ResendVerficationCodeHandler } from '../../redux/actions/Auth';

const VerficationEmail = () => {

    const dispatch = useDispatch();

    const { push } = useHistory();

    const HandelResend = (e) => {
        e.preventDefault();
        dispatch(ResendVerficationCodeHandler(() => {
            push('/verfication-email')
        }))
    }

    return(
        <section className="auth">
            <div className="container">
                <div className="auth-body">
                    <div className="auth-header">
                        <h1 className='title' style={{lineHeight: "1.6"}}>Please Verify Your The Garage Account Email Address and Try Again</h1>
                    </div>
                    <div className='auth-form' style={{textAlign: "center"}}>
                        <button className="btn" style={{margin: "0 auto"}} onClick={HandelResend}>Verify Your Email Account</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VerficationEmail;