import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ForgetPassword from "../component/auth/ForgetPassword";
import { useHistory } from "react-router-dom";
import { LocalKeys } from "../helpers/config";

const LostPassword = () => {

    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem(LocalKeys.TOKEN)) {
            history.push("/profile");
        }
    }, []);

    return(
        <>
            <PageHelmet pageTitle='Forgotten Password' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Forgotten Password'} isbread={true}  />
            <main className="page-wrapper">
                <ForgetPassword />
            </main>
            <Footer />
        </>
    )
}

export default LostPassword;