import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetSoundEngineersDataHandler } from "../../redux/actions/Engineers";

const Engineers = () => {

    const dispatch = useDispatch();

    const { EngineersData } = useSelector((state) => state.bookingData);

    useEffect(() => {
        dispatch(GetSoundEngineersDataHandler());
    },[dispatch])

    return(
        <section className='wizzard'>
            <div className="container">
                <div className="wizzard-body" style={{ background:"none", boxShadow:"none" }}>
                    <h3 className="step-title">Select Sound Engineer</h3>
                    <div className="engineers-container">
                        <div className="row">
                            {
                                EngineersData.map( item => (
                                    <div className="col-md-3" key={item.id}>
                                        <Link to={`${process.env.PUBLIC_URL + "/book/" + item.id }`} className="engineers-box">
                                            <div className="engineers-img">
                                                <img src={item.image} alt={item.name} />
                                            </div>
                                            <h4 className="engineers-name">{item.name}</h4>
                                        </Link>
                                    </div>
                                ) )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Engineers;