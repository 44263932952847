import React from "react";
import PinInput from "react-pin-input";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { SendVerficationCodeHandler } from "../../../redux/actions/Auth";
import { ResendVerficationCodeHandler } from "../../../redux/actions/Auth";

const Verfication = () => {

    const dispatch = useDispatch();

    const { push } = useHistory();

    const HandelResend = (e) => {
        e.preventDefault();
        dispatch(ResendVerficationCodeHandler());
    }

    return(
        <div className="auth-body">
            <div className="auth-header">
                <h1 className='title'>Verify Your Email </h1>
            </div>
            <div className='auth-form' style={{textAlign: "center"}}>
                <PinInput 
                    length={6} 
                    initialValue=""
                    type="numeric" 
                    inputMode="number" 
                    onComplete={(value, index) => {
                        dispatch(SendVerficationCodeHandler(value, () => {
                            push('/profile');
                        }))
                    }}
                    autoSelect={true}
                    focus={true}
                    regexCriteria={/^[ 0-9_@./#&+-]*$/}
                />
            </div>
            <p className="form-text">Please enter the verification code you received in your email.</p>
            <div className="feats">
                <div className="auth-info">
                    Don't Receive Verification Code? <Link to="/" onClick={HandelResend}>Resend Code</Link>
                </div>
            </div>
        </div>
    )
}

export default Verfication;