const ActionTypes = {
    USER_LOGIN : "USER_LOGIN",
    USER_LOGOUT : "USER_LOGOUT",
    USER_DATA : "USER_DATA",
    USER_UPDATE_DATA : "USER_UPDATE_DATA",
    ENGINEERS_DATA : "ENGINEERS_DATA",
    DATE_OFF : "DATE_OFF",
    BOOKING_FORM : "BOOKING_FORM",
    USER_REGISTER : "USER_REGISTER",
    SEND_VERFICATION_CODE : "SEND_VERFICATION_CODE",
    RESEND_VERFICATION_CODE : "RESEND_VERFICATION_CODE",
    FORGET_PASSWORD : "FORGET_PASSWORD",
    RESET_PASSWORD : "RESET_PASSWORD",
    BOOKING_DATA : "BOOKING_DATA",
    CANCEL_BOOKING : "CANCEL_BOOKING",
    INFORMATION_DATA : "INFORMATION_DATA",
    CHECK_VERFICATION_CODE : "CHECK_VERFICATION_CODE",
    SERVICES_PAGE_DATA : "SERVICES_PAGE_DATA",
    ABOUT_PAGE_DATA : "ABOUT_PAGE_DATA",
    WORKS_PAGE_DATA : "WORKS_PAGE_DATA",
    BOOKING_DETAILS : "BOOKING_DETAILS",
    HOME_PAGE_DATA : "HOME_PAGE_DATA",
    WORK_CATEGORIES :  "WORK_CATEGORIES",
};

export default ActionTypes;