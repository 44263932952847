import React from 'react'
import { Link } from 'react-router-dom';

const HomeAbout = ({title,subTitle,description,img,page,mission,vission}) => {

    return(
        <div className="about-area ptb--120  bg_color--1">
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5">
                            <div className="thumbnail">
                                <img className="w-100" src={img} alt="About Images"/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{title}</h2>
                                    <h4>{subTitle}</h4>
                                    <p className="description">{description}</p>
                                </div>
                            </div>
                            {
                                page === 'about' ? 
                                <div className='row mt-4'>
                                    {/* <div className="col-md-6">
                                        <h4>Our Mission</h4>
                                        <p>{mission}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h4>Our Vission</h4>
                                        <p>{vission}</p>
                                    </div> */}
                                </div> :
                                <div className="view-more-btn mt--60 text-left">
                                    <Link className="rn-button-style--2 btn-solid" to={`${process.env.PUBLIC_URL + "/about"}`} style={{backgroundColor: "#fbc723", color:"#000", borderColor: "#fbc723"}}><span>READ MORE</span></Link>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeAbout