import { toast } from "react-toastify";


export const ShowTostHandler = (notfiction, type) => {
    toast(notfiction, {
        position:  "top-left",
        type: type,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};