import { axiosAPI  } from "../../helpers/config";
import ActionTypes from '../../helpers/ActionTypes';


// Handle Service Page Data

const ServicesPageData = (payload) => ({
    type: ActionTypes.SERVICES_PAGE_DATA,
    payload: payload,
});

export const GetServicesPageDataHandler = () => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.get("services");
        if(data.status_code === 200){
            dispatch(ServicesPageData(data.data));
        }

    } catch (error) {
        console.log(error.response); 
    }
    };
};