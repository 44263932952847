import { axiosAPI } from "../../helpers/config";
import ActionTypes from '../../helpers/ActionTypes';
import { ShowTostHandler } from "../../helpers/help";

// Handle Booking Form

const BookingFormData = (payload) => ({
    type: ActionTypes.BOOKING_FORM,
    payload: payload,
});


export const BookingFormHandler = (formData, cb) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("bookings", formData);
        if(data.status_code === 200){
            dispatch(BookingFormData(data.data));
            ShowTostHandler(data.message, "success");
            cb && cb();
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handle Booking Appointments

const BookingData = (payload) => ({
    type: ActionTypes.BOOKING_DATA,
    payload: payload,
});


export const BookingDataHandler = (cb) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.get("mybooking");
        if(data.status_code === 200){
            dispatch(BookingData(data.data));
            cb && cb();
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};


// Handle Cancel Booking

const CancelBookingData = (payload) => ({
    type: ActionTypes.CANCEL_BOOKING,
    payload: payload,
});


export const CancelBookingHandler = (id) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("cancel-booking", {
            booking_id : id
        });
        if(data.status_code === 200){
            dispatch(CancelBookingData(data.data));
            ShowTostHandler(data.message, "success");
            setTimeout(() => {
                window.location.reload();
            }, 1000)
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};

// Handle Single Booking Booking

const SingleBookData = (payload) => ({
    type: ActionTypes.BOOKING_DETAILS,
    payload: payload,
});


export const SingleBookingHandler = (id) => {
    return async (dispatch) => {
    try {
        const { data } = await axiosAPI.post("get-booking", {
            booking_id : id
        });
        if(data.status_code === 200){
            dispatch(SingleBookData(data.data));
        } 

    } catch (error) {
        console.log(error.response); 
    }
    };
};



